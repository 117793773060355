<template>
  <div class="form-row">
    <div class="form-group col-md-12">
      <div class="text-center" v-if="oldzapvisible">
        <a href="#" @click.prevent="getOldOrderZap"
          >выбрать запчасти из ранее приобретенных</a
        >
      </div>
      <div class="text-center">
        <a href="#" @click.prevent="getNewOrderZap">{{ newZapTitle }}</a>
      </div>
    </div>
    <div class="form-group col-md-12" v-if="visibleAddNewOrderZap || visible">
      <div class="customselect">
        <input
          type="text"
          v-model="input"
          @input="search"
          class="form-control form-control-sm input-form shadow-none text-left"
          :placeholder="
            title ||
              'поиск запчастей для ремонта (по артикулу или наименованию)'
          "
        />
        <div v-if="prompt" class="prompt">
          <div>поиск запчастей только из тех что есть в наличии</div>
          <div>искать можно по нескольким ключевым словам через пробел</div>
        </div>
        <div v-if="visibleSearchData" class="customselect-result">
          <table class="customselect-table t-search" v-if="searchdata.length">
            <thead>
              <tr>
                <th>наименование</th>
                <th>артикул</th>
                <th>код</th>
                <th>остаток</th>
                <th>цена</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in searchdata"
                :key="item.cod"
                @click="selectZap(item.cod, item.name, item.art, item.cena)"
              >
                <td
                  class="search"
                  v-html="backLightFilter(item.name, input)"
                ></td>
                <td
                  class="search"
                  v-html="backLightFilter(item.art, input)"
                ></td>
                <td
                  class="search"
                  v-html="backLightFilter(item.cod, input)"
                ></td>
                <td class="text-center">{{ item.ostatok }}</td>
                <td class="text-center">{{ item.cena }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center">
            по запросу <span class="search-text">{{ input }}</span> ничего не
            найдено
          </div>
        </div>
        <div v-else />
      </div>
    </div>
    <div v-else />
    <div v-if="visibleUserZap && oldzapvisible" class="form-group col-md-12">
      <div class="form-row fff">
        <input
          type="text"
          class="form-control form-control-sm input-form shadow-none search-nakl text-left col-md-10"
          v-model="inputSearchNakl"
          @input="filterNakl"
          placeholder="поиск накладной по номеру"
        />
        <button
          class="btn btn-sm btn-light shadow-none col-md-2 btn-close"
          @click="closeUserZapSearch"
        >
          закрыть
        </button>
      </div>
      <div class="user-zap">
        <table class="table table-sm t-search">
          <tr
            v-for="item in userZap"
            :key="item.data.id"
            @click="selectNakl(item.data.newId)"
          >
            <td>
              {{ `${item.data.nomer_nakl} от ${item.data.data_nakl}` }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="visibleSelectNakl && naklId" class="col-md-12">
      <button
        class="btn btn-sm btn-light shadow-none btn-back"
        @click="backListNakl"
      >
        <i class="bi bi-arrow-left"></i> назад к списку накладных
      </button>
      <div class="user-zap">
        <table class="table table-sm t-search">
          <tr
            v-for="(item, idx) in zap[0].z"
            :key="idx"
            @click="
              selectUserZap(
                item.cod,
                item.art,
                item.cena,
                item.name,
                1,
                item.id
              )
            "
          >
            <td>{{ idx + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.art }}</td>
            <td>{{ item.cena }}</td>
            <td>{{ item.new_pcs }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="ZAP.length" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th :colspan="cenaVisible ? 6 : 4" class="text-center">
              новый заказ
            </th>
          </tr>
          <tr>
            <th class="text-center">наименование</th>
            <th class="text-center">артикул</th>
            <th class="text-left">шт.</th>
            <th v-if="cenaVisible" class="text-left">цена</th>
            <th v-if="cenaVisible" class="text-left">сумма</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in ZAP" :key="idx">
            <td>{{ item.name }}</td>
            <td class="text-center">{{ item.art }}</td>
            <td class="text-center">
              <input
                class="form-control form-control-sm input-form shadow-none fs07"
                type="number"
                v-model="ZAP[idx].pcs"
                min="1"
              />
            </td>
            <td v-if="cenaVisible">{{ item.cena }}р.</td>
            <td v-if="cenaVisible">{{ item.cena * ZAP[idx].pcs }}р.</td>
            <td>
              <i class="bi bi-x" @click="deleteZap(idx)"></i>
            </td>
          </tr>
          <tr v-if="cenaVisible">
            <td colspan="5" class="text-right">итого: {{ sum() }}р.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ModalMessage
      :message="message"
      @hideMessage="hideMessage"
      v-if="visibleMessage"
    />
    <div v-if="USERZAPORDER.length" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center">из ранее приобретенных</th>
          </tr>
          <tr>
            <th class="text-center">наименование</th>
            <th class="text-center">артикул</th>
            <th class="text-center">шт.</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in USERZAPORDER" :key="idx">
            <td>{{ item.name }}</td>
            <td class="text-center">{{ item.art }}</td>
            <td class="text-center">{{ item.pcs }}</td>
            <td>
              <i class="bi bi-x" @click="deleteZapFromUserZap(idx)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ModalMessage from "@/components/ModalMessage";
export default {
  name: "UserForRepair",
  components: {
    ModalMessage
  },
  computed: {
    ...mapGetters(["USERZAP", "USERZAPORDER", "ZAP", "LIST_ZAP"])
  },
  props: [
    "dataZap",
    "downloadPercentage",
    "zapForRepair",
    "oldzapvisible",
    "newZapTitle",
    "cenaVisible",
    "visible",
    "getParams",
    "title",
    "prompt",
    "id_user"
  ],
  data: () => ({
    input: "",
    searchdata: null,
    visibleSearchData: false,
    cod: null,
    visibleAddNewOrderZap: false,
    visibleUserZap: false,
    visibleSelectNakl: false,
    naklId: null,
    inputSearchNakl: "",
    userZap: null,
    zap: null,
    visibleMessage: false,
    message: "",
    zap2: null
  }),
  mounted() {
    this.userZap = this.USERZAP;
    this.zap2 = this.ZAP;
    if (this.visible) {
      this.GET_ZAP(this.getParams);
    }
  },
  methods: {
    ...mapActions(["GET_ZAP"]),
    ...mapMutations([
      "setUserZap",
      "addInOrderUserZap",
      "DeleteInOrderUserZap",
      "userZapOrderNulll",
      "addZap",
      "deleteZap2",
      "zapNull",
      "userZapListNull"
    ]),
    deleteZap(idx) {
      this.deleteZap2(idx, 1);
    },
    closeUserZapSearch() {
      this.visibleUserZap = false;
    },
    deleteZapFromUserZap(idx) {
      this.DeleteInOrderUserZap(idx);
    },
    hideMessage() {
      this.visibleMessage = false;
    },
    backListNakl() {
      this.visibleSelectNakl = false;
      this.visibleUserZap = true;
    },
    filterNakl() {
      this.userZap = this.USERZAP.filter(item =>
        item.data.nomer_nakl.includes(this.inputSearchNakl)
      );
    },
    selectNakl(id) {
      this.visibleSelectNakl = true;
      this.visibleUserZap = false;
      this.zap = this.USERZAP.filter(item => item.data.newId.includes(id));
      this.naklId = id;
    },
    selectUserZap(cod, art, cena, name, pcs, id) {
      const pcsInNakl = this.zap[0].z.filter(item => item.cod.includes(cod));
      const data = {
        cod,
        art,
        cena,
        name,
        pcs,
        id
      };
      this.addInOrderUserZap(data);
      const pcsInOrder = this.USERZAPORDER.filter(item =>
        item.cod.includes(cod)
      );
      if (pcsInOrder[0].pcs > pcsInNakl[0].new_pcs) {
        this.USERZAPORDER.map(item => {
          if (item.cod === cod) {
            item.pcs = item.pcs - 1;
            this.message = "нельзя добавить больше чем осталось в накладной";
            this.visibleMessage = true;
          }
        });
      }
      this.oldzap();
    },
    search() {
      this.input = this.input.replace(/^\s*(.*)\s*$/, "$1");
      if (this.input.length > 2) {
        const str = this.input.split(" ");
        let result = this.LIST_ZAP;
        for (let i = 0; i < str.length; i++) {
          str[i] = str[i].replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
          const reg = new RegExp(str[i], "gi");
          result = result.filter(
            item =>
              reg.test(item.name) || reg.test(item.art) || reg.test(item.cod)
          );
        }
        this.searchdata = result;
        this.visibleSearchData = true;
      } else {
        this.searchdata = null;
        this.visibleSearchData = false;
      }
    },
    backLightFilter(text, input) {
      const splitinput = input.split(" ");
      for (let i = 0; i < splitinput.length; i++) {
        text = text
          .toLowerCase()
          .replace(
            splitinput[i].toLowerCase(),
            `<span class="customselect-selected">${splitinput[i]}</span>`
          );
      }
      return text;
    },
    selectZap(cod, name, art, cena) {
      this.visibleSearchData = false;
      this.input = "";
      this.cod = cod;
      this.addZap({ cod, name, art, pcs: 1, cena });
    },
    addPerzent(item) {
      if (item > 0) {
        return `${item}%`;
      }
      if (!item) {
        return "";
      }
    },
    getNewOrderZap() {
      //if (!this.zapForRepair.length) {
      this.GET_ZAP();
      this.visibleAddNewOrderZap = !this.visibleAddNewOrderZap;
      //}
    },
    getOldOrderZap() {
      if (!this.visibleUserZap && !this.visibleSelectNakl) {
        this.oldzap();
      }
      if (!this.visibleSelectNakl) {
        this.visibleUserZap = true;
      }
    },
    oldzap() {
      HTTPGET({
        url: "repairs/user_zap/get_user_zap.php",
        params: {
          id: this.id_user
        }
      })
        .then(response => this.setUserZap(response.data))
        .then(() => (this.userZap = this.USERZAP));
    },
    sum() {
      let itog = 0;
      this.ZAP.map(item => (itog = itog + item.cena * item.pcs));
      return itog;
    }
  },
  destroyed() {
    this.userZapOrderNulll();
    this.zapNull();
    this.userZapListNull();
  }
};
</script>

<style lang="scss" scoped>
.t-search {
  width: 100%;
  border: 1px solid lightgray;
  border-collapse: collapse;
  font-size: 0.7em;
}
input {
  text-align: center;
}
.t-search td,
.t-search th {
  border: 1px solid lightgray;
  padding: 3px;
  vertical-align: middle;
}
.customselect {
  position: relative;
  margin-top: 10px;
  &-result {
    position: absolute;
    top: 2em;
    z-index: 3;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    background: white;
    border: 1px solid lightgray;
  }
  &-table {
    width: 100%;
  }
  &-selected {
    background: yellow;
  }
}
.t-search tr:hover {
  cursor: pointer;
  background: lightgray;
}
.customselect-selected {
  background: yellow;
}
.search-text {
  color: red;
}
.btn-back {
  margin-bottom: 3px;
}
.btn-close {
  font-size: 0.7em;
}
.fff {
  padding: 5px;
}

.bi-x {
  font-size: 2em;
  cursor: pointer;
  color: red;
}
table {
  font-size: 0.7em;
}
.table td {
  vertical-align: middle;
}
.fs07 {
  font-size: 1em;
  width: 4em;
}
</style>
<style>
.customselect-selected {
  background: yellow;
}
.user-zap {
  height: 200px;
  overflow: auto;
}
.search-nakl {
  font-size: 0.7em;
}
.prompt {
  font-size: 0.7em;
  color: #6c757d;
}
</style>
